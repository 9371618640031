.monile_nav {
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: -110%;
  top: 0;
  background-color: $green;
  z-index: 999999999999999;
  a {
    color: #fff;
    font-size: 20px;
    font-weight: 300;
    text-transform: uppercase;
    padding: 10px 33px;
    display: inline-block;
    &:hover {
      color: rgba(13, 79, 37, 1);
    }
  }
  .m_close {
    margin: 15px 33px;
    text-align: right;
    padding: 0;
    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
  }
  ul {
    list-style: none;
    padding-left: 0;
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    .drop-down {
      > a {
        position: relative;
        &::after {
          position: absolute;
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          right: -15px;
          top: 7px;
          color: #0d4f25;
          content: "\f0d7";
          transition: all 0.7s;
        }
      }
      a.active::after {
        transform: rotateX(180deg);
        color: #fff;
      }
    }
    ul {
      padding: 0;
      display: none;
      background-color: rgba(13, 79, 37, 0.9);
      li a {
        &:hover {
          color: #fff;
        }
      }
    }
  }

  .phone {
    margin-top: 35px;
    i {
      margin-right: 10px;
    }
  }
  .social {
    flex-direction: row;
    justify-content: flex-start;
    margin-left: 33px;
    float: left;
    a {
      padding: 0;
      margin-right: 15px;

      &:hover {
        color: rgba(13, 79, 37, 0.9);
      }
    }
  }
  .lang {
    float: right;
    margin-right: 33px;
    margin-top: 15px;
    display: block;
    li {
      display: inline-block;
    }
    a {
      padding: 0;
      margin-right: 15px;
    }
  }
}
