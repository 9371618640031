.products {
  margin-top: 36px;
  margin-bottom: 36px;
  list-style: none;
  padding-left: 0;
  > ul {
    list-style: none;
    padding-left: 0;
  }
  .row {
    justify-content: flex-start;
    padding-left: 0;
  }
  .product {
    position: relative;
    .product-head {
      color: #4a4a4a;
      font-weight: bold;
      h3 {
        font-size: 35px;
        margin: 0;
      }
      h4 {
        font-size: 20px;
        margin: 10px 0;
        margin-top: 30px;
      }
    }
    p {
      color: #4a4a4a;
      font-weight: 300;
      font-size: 14px;
    }
    width: 31%;
    margin-right: 3.5%;
    margin-bottom: 30px;
    &:hover .product-avatar img {
      transform: scale(1.4);
    }
    &:nth-child(3n) {
      margin-right: 0;
    }

    .product-avatar {
      overflow: hidden;
      position: relative;
      border: 3px solid #e4e4e4;
      > div {
        position: absolute;
        display: flex;
        padding: 10px;
        left: 0;
        bottom: 0;
        div {
          padding: 15px;
          min-width: 80px;
          background-color: $dark;
          span {
            display: block;
            text-align: center;
            font-weight: 700;
            font-size: 15px;

            color: #fff;
            &:first-of-type {
              font-size: 30px;
            }
          }
          &:first-of-type {
            background-color: $green;
          }
        }
      }
      img {
        transition: all 0.9s;
        cursor: pointer;
        display: block;
        width: 100%;
        &:hover {
          transform: scale(1.4);
        }
      }
    }
    ul {
      list-style: none;
      display: flex;
      justify-content: flex-start;
      margin: 15px 0;
      padding: 0;
      li {
        margin-right: 10px;
      }
    }
  }
}
@media only screen and (max-width: 960px) {
  .products {
    .product {
      width: 47%;
      margin-right: 3%;

      &:nth-child(3n) {
        margin-right: 3%;
      }
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}
@media only screen and (max-width: 700px) {
  .products {
    .product {
      width: 100%;
      margin-right: 0;
    }
  }
}
.products.other_products_slider .product {
  margin-right: 10px !important;
}
