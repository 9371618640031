@mixin respond-to($media) {
  @media only screen and (max-width: $media) {
    @content;
  }
}
@mixin rowFlex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

@mixin playButton {
  background-color: rgba($color: $green, $alpha: 0.7);
  color: #fff;
  position: absolute;
  font-size: 30px;
  width: 68px;
  height: 68px;
  border-radius: 50%;
  transition: all 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  margin-left: -34px;
  margin-top: -34px;
  cursor: pointer;
  &:hover {
    background-color: rgba($color: #0d4f25, $alpha: 0.7);
  }
  i {
    display: inline-block;
    transform: translateX(3px);
  }
}

@mixin button {
  cursor: pointer;
  background-color: $green;
  border: none;
  font-weight: 300;
  font-size: 14px;
  color: #fff;
  padding: 15px 22px;
  text-transform: uppercase;
  transition: all 0.5s;
  height: 60px;
  &:hover {
    background-color: #0d4f25;
  }
}

// @font-face {
//   font-family: "Montserrat";
//   src: url("../webfonts/monsterratextralight.woff2") format("woff2"),
//     url("../webfonts/monsterratextralight.woff") format("woff"),
//     url("../webfonts/monsterratextralight.ttf") format("truetype");
//   font-weight: 200;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Montserrat";
//   src: url("../webfonts/montserratlight.woff2") format("woff2"),
//     url("../webfonts/montserratlight.woff") format("woff"),
//     url("../webfonts/montserratlight.ttf") format("truetype");
//   font-weight: 300;
//   font-style: normal;
// }
// @font-face {
//   font-family: "Montserrat";
//   src: url("../webfonts/montserrat.woff2") format("woff2"),
//     url("../webfonts/montserrat.woff") format("woff"),
//     url("../webfonts/montserrat.ttf") format("truetype");
//   font-weight: 400;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Montserrat";
//   src: url("../webfonts/montserratbold.woff") format("woff"),
//     url("../webfonts/MontserratAlternates-Bold.otf") format("truetype");
//   font-weight: 700;
//   font-style: normal;
// }

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Light"), local("Montserrat-Light"),
    url("../webfonts/Montserrat-Light.otf");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"), local("Montserrat-Regular"),
    url("../webfonts/Montserrat-Regular.otf");
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Bold"), local("Montserrat-Bold"),
    url("../webfonts/Montserrat-Bold.otf");
  font-weight: 700;
  font-style: normal;
}
