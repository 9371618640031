.about {
  margin-bottom: 72px;
  img {
    width: 100%;
  }
  background-color: #e6f1ed;
  position: relative;
  z-index: 3;
  padding-top: 48px;
  padding-bottom: 48px;
  .row > div {
    justify-content: space-between;
    align-self: flex-start;
  }

  &:after {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    height: 100%;
    width: 55%;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 25% 100%);
    background-color: #343434;
    right: 0;
  }
  .video {
    position: relative;
    width: 50%;
    .inlinePlayButton {
      background: $green;
    }
    .product_nav {
      @include playButton;
    }
  }
  .about_desc {
    width: 45%;
    p {
      font-weight: 300;
      margin-top: 0;
      font-size: 14px;
      line-height: 22px;
      color: #fff;
      margin-bottom: 35px;
    }
  }
}
@media only screen and (max-width: 960px) {
  .about {
    padding-top: 24px;
    .row > div {
      width: 100%;
      margin-bottom: 35px;
    }
    background-color: #343434;
    &:after {
      width: 100%;
      height: 50%;
      margin-bottom: 10px;
      background-color: #e6f1ed;
      top: 0;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 65%);
    }
  }
}
