.contacts {
  @include rowFlex;
  align-items: stretch;
  margin-bottom: 60px;
  div {
    width: 40%;
    @include respond-to(760px) {
      width: 100%;
    }
    ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 60px;
      width: 347px;
      @include respond-to(900px) {
        width: 100%;
      }
      li {
        margin-bottom: 15px;
        padding-left: 30px;
        position: relative;
        a {
          color: #4a4a4a;
          font-weight: 300;
          font-size: 18px;
        }
        &::before {
          color: $green;
          position: absolute;
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          left: 0;
          top: 0;
        }
        &:nth-child(1)::before {
          content: "\f3c5";
        }
        &:nth-child(2)::before {
          content: "\f095";
        }
        &:nth-child(3)::before {
          content: "\f10b";
        }
        &:nth-child(4)::before {
          content: "\f0e0";
        }
      }
    }
    form {
      width: 100%;
      input,
      textarea {
        color: #333333;
        font-weight: 300;
        font-size: 18px;
        padding: 10px;
        background-color: #f6f6f6;
        display: block;
        margin-bottom: 15px;
        border-color: #f6f6f6;
        border-radius: 5px;
        border-style: solid;
        width: 347px;
        @include respond-to(900px) {
          width: 100%;
        }
        &:focus {
          border-color: $green;
          background-color: #fff;
          outline: none;
        }
      }
      button {
        height: 55px;
        font-size: 14;
        font-weight: 300;
        min-width: 184px;
        color: #fff;
        background: $green;
        border: none;
        cursor: pointer;
        &:hover {
          background: #0d4f25;
        }
      }
    }
  }
  .map {
    width: 58%;
    padding-bottom: 30px;
    position: relative;
    @include respond-to(760px) {
      width: 100%;

      height: 400px;
    }

    #show_big_map_form {
      padding: 10px;
      position: absolute;
      left: 50%;
      margin-left: -215px;
      bottom: 0;
      width: 429px;
      height: 77px;
      @include rowFlex;
      align-items: center;
      input,
      button {
        width: 49%;
      }
      background: #fff;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);

      input {
        color: #9b9b9b;
        background: #fff;
        font-size: 18px;
        font-weight: 300;
        border: none;
        align-self: center;
        margin-bottom: 0;
        @include respond-to(840px) {
          width: 100%;
        }
      }
      button {
        @include button;

        @include respond-to(840px) {
          width: 100%;
        }
      }

      @include respond-to(840px) {
        margin-left: 0;
        left: 5%;
        width: 90%;
      }
    }
  }
}
#simple_map {
  width: 100%;
  height: 100%;
}
input:focus::placeholder {
  color: transparent;
}
textarea:focus::placeholder {
  color: transparent;
}
input:focus,
textarea:focus {
  background: #fff;
}
