.photo_gallery {
  list-style: none;
  overflow-x: hidden;
  padding-left: 0;
  width: 100%;
  height: 354px;
  img {
    height: 354px;
    width: 531px;
  }
  @include respond-to(820px) {
    height: 200px;
    img {
      height: 200px;
    }
  }
}

.gallery_content {
  text-align: center;
  width: 531px;

  @include respond-to(900px) {
    width: 100%;
  }
  margin: 0 auto;
  h3 {
    font-weight: bold;
    color: #4a4a4a;
    font-size: 30px;
    line-height: 1;
  }
}

.video_gallery {
  margin-bottom: 50px;
  @include rowFlex;
  align-items: flex-start;
  ul {
    width: 35%;
    margin: 0;
    height: 380px;
    overflow-y: hidden;
    padding-left: 0;
    li {
      @include rowFlex;
      align-items: flex-start;
      flex-wrap: nowrap;
      margin-bottom: 15px;
      padding-right: 30px;
      height: 92px;
      overflow: hidden;
      font-size: 14px;
      font-weight: 300;
      color: #4a4a4a;
      cursor: pointer;
      img {
        width: 40%;
        margin-right: 15px;
        display: block;
      }
      span {
        width: 60%;
        display: block;
      }
    }
    @include respond-to(767px) {
      width: 100%;
    }
  }

  > div {
    width: 63%;
    overflow: hidden;
    @include respond-to(767px) {
      width: 100%;
      margin-bottom: 30px;
    }
    a {
      @include playButton;
      z-index: 9;
    }
    div:first-child {
      position: relative;
      height: 380px;
      overflow: hidden;
      iframe {
        width: 100%;
        height: 100%;
      }
      img {
        width: 100%;
        position: absolute;
        height: auto;
      }
    }

    h4 {
      font-size: 30px;
      font-weight: 700;
      color: #4a4a4a;
      margin: 15px 0;
      margin-bottom: 5px;
    }
    data {
      font-weight: 300;
      font-size: 14px;
      color: #4a4a4a;
    }
  }
}

#mCSB_1_dragger_vertical {
  background: #e6f1ed;
  border-radius: 11.5px;
  // width: 23px !important;
  // height: 95px !important;
}

.mCSB_dragger_bar {
  background: #e6f1ed !important;
}
.mCSB_draggerRail {
  background: transparent !important;
}
.mCSB_scrollTools .mCSB_buttonUp {
  opacity: 1 !important;
  position: relative;

  &::before {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent $green transparent;
  }
}

.mCSB_buttonDown {
  opacity: 1 !important;
  position: relative;
  &::before {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8px 0 8px;
    border-color: $green transparent transparent;
  }
}
