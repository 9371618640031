.production {
  @include rowFlex;
  .content {
    width: 63%;
    @include respond-to(680px) {
      width: 100%;
    }
  }
  aside {
    margin-top: 40px;
    width: 33%;
    align-self: stretch;
    border-left: 3px solid #d8d8d8;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    > div {
      width: 150px;
      text-align: center;
      padding-bottom: 10px;
      position: relative;
      margin-bottom: 50px;
      @include respond-to(680px) {
        margin-bottom: 20px;
      }
      &::after {
        content: "";
        position: absolute;
        height: 3px;
        background: #d8d8d8;
        left: 30px;
        right: 30px;
        bottom: 0;
      }
      span {
        color: #4a4a4a;
        font-size: 18px;
        font-weight: 300;
      }
      .counter {
        color: $green;
        font-size: 60px;
        font-weight: bold;
        text-align: center;
      }
      span {
        &::after {
          display: none;
        }
      }
    }
    @include respond-to(680px) {
      width: 100%;
      border-left: none;
      margin-top: 0;
    }
  }
}

.production_nav {
  position: absolute;
  z-index: 99999;
  left: 0;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  span {
    cursor: pointer;
    color: #4a4a4a;
    transition: all 0.5s;
    font-size: 30px;
    margin-top: -15px;
    &:hover {
      opacity: 0.8;
      color: $green;
    }
  }
}
