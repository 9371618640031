nav {
  background-color: $green;
  padding: 0;
  a {
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    text-transform: uppercase;
  }
  .row {
    justify-content: flex-end;
    height: 70px;
    padding: 0;
    > ul {
      list-style: none;
      margin: 0;
      padding: 0;
      > li {
        transition: all 0.5s;
        position: relative;
        padding: 0;
        margin: 0;
        &:hover {
          background-color: #0d4f25;
          ul {
            display: block;
            opacity: 1;
            transform: translateY(0);
          }
        }
        > a {
          display: flex;
          height: 70px;
          position: relative;
          justify-content: center;
          align-items: center;
          padding-left: 15px;
          padding-right: 15px;
          &::before {
            content: "";
            position: absolute;
            left: -1px;
            top: 26px;
            height: 16px;
            width: 1px;
            background: #0d4f25;
          }

          &:hover::before {
            display: none;
          }
        }
        ul {
          position: absolute;
          list-style: none;
          display: none;
          opacity: 0;
          transform: translateY(30px);
          transition: all 0.5s;
          background-color: rgba(13, 79, 37, 0.9);
          left: 0;
          top: 100%;
          margin: 0;
          padding: 26px 13px 0 13px;
          min-width: 165px;
          z-index: 99999999999999999999999999999;
          li {
            margin-bottom: 26px;
            a:hover {
              color: $green;
            }
          }
        }

        &:first-child {
          a::before {
            display: none;
          }
        }
      }
    }
  }

  .search,
  .navbar {
    margin-left: 37px;
    padding: 26px 0;
    margin-top: 5px;
    span {
      cursor: pointer;
      transition: all 0.5s;
      color: #fff;
      font-size: 20px;

      &:hover {
        color: #0d4f25;
      }
    }
  }
  .navbar {
    margin-left: 23px;
    padding-right: 5px;
    display: none;
    span {
      font-size: 28px;
    }
  }
}
@media only screen and (max-width: 1310px) {
  .nav {
    width: 75%;
    flex: 1;
  }
  nav ul.row {
    display: none;
  }
  .navbar {
    display: block !important;
  }
}

.hideDrop::before {
  display: none !important;
  opacity: 0;
}
