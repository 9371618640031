header {
  position: relative;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  .logo {
    position: absolute;
    transition: all 0;
    left: 0;
    background: #fff;
    width: 25%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 10%;

    img {
      width: 176px;
    }
    a {
      width: 100%;
      display: block;
      padding-right: 10px;
    }
  }
  .top {
    a,
    span {
      font-weight: 400;
      cursor: pointer;
      transition: all 0.5s;
      font-size: 12px;
      color: #9b9b9b;
      &:hover {
        color: #fff;
      }
    }
    .row {
      justify-content: flex-end;
      .lang {
        margin-left: 26px;
      }

      .social {
        margin-right: 26px;
        li {
          margin-right: 12px;
        }
        a {
          font-size: 17px;
          color: #fff;
          &:hover {
            color: $green;
          }
        }
      }
      .lang {
        list-style: none;
        padding-left: 0;
        li {
          margin-right: 12px;
          display: inline-block;
          &:last-child {
            margin-right: 0;
          }
          a {
            font-size: 12px;
          }
        }
      }
      .phone {
        a {
          i {
            margin-right: 12px;
            transition: all 0.5s;
          }
          &:hover i {
            color: $green;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  header {
    display: flex;
    .logo {
      position: static;
      flex-basis: 300px;
      align-self: center;
      img {
        width: 160px;
      }
    }
  }
}
@media only screen and (max-width: 960px) {
  header {
    .logo {
      flex-basis: 50%;
      img {
        width: 120px;
      }
    }
    .top {
      height: 20px;
      .row {
        display: none;
      }
    }
  }
}
