.slideContainer {
  position: relative;
  max-width: 1660px;
  margin: 0 auto;
  .item {
    position: relative;
    z-index: 99;
    img {
      display: block;
      width: 100%;
    }
    .slideContent {
      position: absolute;
      left: 0;
      width: 100%;
      z-index: 999999999999;
      bottom: -30px;
      display: flex;
      align-items: flex-end;
      .container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
      }
      a {
        color: #fff;
        display: inline-block;
        padding: 15px 31px;
        font-weight: 300;
        font-size: 45px;
        background: $green;
        h2 {
          margin: 0;
          font-weight: 300;
          font-size: 45px;
        }
        opacity: 0;
      }
      h3 {
        opacity: 0;
        padding: 15px 31px;
        font-weight: bold;
        font-size: 45px;
        background-color: $dark;
        margin: 0;
        color: #fff;
        animation-delay: 300ms;
        animation-duration: 0.4s;
      }
    }
  }

  .slideNav {
    position: absolute;
    bottom: -30px;
    width: 100%;
    background: transparent;
    right: 0;
    .container {
      text-align: right;
    }
    span {
      padding: 24px;
      font-size: 30px;
      display: inline-block;
      color: $green;
      line-height: 0;
      background-color: #fff;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
      transition: all 0.5s;
      cursor: pointer;
      &:first-of-type {
        margin-right: 10px;
      }
      &:hover {
        background-color: $green;
        color: #fff;
      }
    }
  }
}
.slick-list {
  padding-bottom: 30px;
}
@media only screen and (max-width: 960px) {
  .slideContainer {
    .item .slideContent {
      h3,
      a h2 {
        font-size: 18px;
      }
    }
    .slideNav {
      position: static;
      .container {
        text-align: left;
        margin-top: 15px;
        span {
          padding: 15px;
          font-size: 22px;
        }
      }
    }
  }
}
