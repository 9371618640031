.subscribe {
  background-color: $green;
  .row {
    align-items: center;
    padding: 14px 0;
  }
  p {
    color: #fff;
    width: 50%;
    font-weight: 300;
    font-size: 18px;
    margin: 0;
    i {
      margin-right: 15px;
    }
  }
  form {
    width: 50%;
    @include rowFlex;
    margin: 0;
    justify-content: flex-start;
    input,
    button {
      background-color: #fff;
      border: none;
      color: $green;
      font-weight: 300;
      font-size: 14px;
      padding: 10px 15px;
      border: 3px solid #fff;
    }
    input {
      width: 60%;
      max-width: 278px;
      display: block;
      margin-right: 24px;
      @include respond-to(380px) {
        width: 100%;
      }
    }
    button {
      display: block;
      transition: all 0.5s;
      cursor: pointer;
      width: 30%;
      max-width: 185px;
      background-color: transparent;
      color: #fff;
      &:hover {
        background: #fff;
        color: $green;
      }
      @include respond-to(380px) {
        width: 60%;
      }
    }
  }
}
@media only screen and (max-width: 1100px) {
  .subscribe {
    p,
    form {
      width: 100%;
      justify-content: center;
    }

    p {
      display: flex;
      margin-bottom: 15px;
    }
    form {
      input {
        margin-bottom: 15px;
        margin-right: auto;
      }
      button {
        align-self: flex-start;
        margin-right: auto;
      }
    }
  }
}
