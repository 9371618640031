.partners {
  ul {
    @include rowFlex;
    list-style-type: none;
    padding-left: 0;
    li {
      width: 25%;
      margin-bottom: 30px;
      padding: 10px;
      @include respond-to(700px) {
        width: 50%;
      }
      a {
        display: block;
        overflow: hidden;
      }
      img {
        transition: all 0.5s;
        max-width: 100%;
        &:hover {
          transform: scale(1.2);
        }
      }
    }
  }
}
