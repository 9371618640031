.breadcump {
  list-style: none;
  margin: 30px 0;
  padding-left: 0;
  li {
    display: inline-block;
    margin-right: 10px;
    color: #9b9b9b;
    font-size: 12px;
    a {
      color: inherit;
      font-size: 12px;
      font-weight: 400;
    }
  }

  li.active {
    font-weight: bold;
  }
}

.pagination {
  list-style: none;
  padding-left: 0;
  display: flex;
  color: #4a4a4a;
  font-weight: 700;
  margin-bottom: 50px;
  li {
    margin-right: 15px;
    a {
      color: #4a4a4a;
      font-weight: 700;
      &:hover {
        color: $green;
      }
    }
  }
  li.active {
    a {
      color: $green;
    }
  }
}
