.customers {
  ul {
    list-style: none;
    padding: 0;
    li {
      @include rowFlex;
      padding-right: 30px;
      color: #4a4a4a;
      font-weight: 300;
      justify-content: space-between;
      align-items: flex-start;
      width: 50%;
      flex-wrap: nowrap;
      margin-bottom: 50px;
      .customer_avatar {
        overflow: hidden;
        margin-right: 20px;
        width: 32%;
        min-width: 160px;
        img {
          width: 100%;
          transition: all 0.7s;
          display: block;
          cursor: pointer;
          &:hover {
            transform: scale(1.5);
          }
        }
      }
      .customer_description {
        width: 60%;
        align-self: flex-start;
        @include respond-to(480px) {
          width: 100%;
        }
        h4 {
          font-size: 20px;
          font-weight: 700;
          margin: 10px 0;
          a {
            color: inherit;
            font-size: 20px;
            font-weight: 700;
            &:hover {
              color: $green;
            }
          }
        }
        h5 {
          margin: 0;
          font-weight: 300;
          font-size: 14px;
        }
      }
    }
  }
  ul.stars {
    list-style: none;
    padding: 0;
    display: flex;
    margin-bottom: 15px;
    li {
      margin-right: 10px;
      color: $green;
      justify-content: flex-start;
      font-size: 15px;
    }
  }

  .raiting {
    img {
      width: 24px;
      display: inline !important;
    }
  }
  .slick-dots {
    display: flex;
    justify-content: center;
    transform: translateY(-40px);
    height: 30px;
    width: 100%;
    list-style: none;
    li {
      width: 20px !important;
      padding: 0 !important;
      cursor: pointer;
      display: block;
      height: 20px !important;
      border-radius: 50%;
      border: 3px solid #d8d8d8;
      margin-right: 10px;
      button {
        visibility: hidden;
      }
    }

    li.slick-active,
    li:hover {
      background-color: #9b9b9b;
      border-color: #9b9b9b;
    }
  }
}

.other_customers_slider {
  list-style: none;
  padding-left: 0;
  li {
    position: relative;
    > a {
      overflow: hidden;
      display: block;
      img {
        width: 100%;
        transition: all 0.5s;
        height: 280px;
        &:hover {
          transform: scale(1.2);
        }
        @include respond-to(1060px) {
          height: 220px;
        }
        @include respond-to(780px) {
          height: 280px;
        }
        @include respond-to(400px) {
          height: 220px;
        }
      }
    }

    > div {
      position: absolute;
      z-index: 999;
      left: 0;
      bottom: 0;
      width: 100%;
      color: #fff;
      padding: 10px;
      span {
        font-size: 14px;
        font-weight: 300;
      }
      h4 {
        margin: 0;
      }
      a {
        color: #fff;
        font-size: 20px;
        font-weight: bold;
        overflow: hidden;
        &:hover {
          color: $green;
        }
      }
    }
    &::after {
      position: absolute;
      content: "";
      left: 0;
      bottom: 0;
      width: 100%;
      height: 50%;
      background: rgba(0, 0, 0, 0)
        linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0) 0px,
          rgba(0, 0, 0, 0.3) 100%
        )
        repeat 0 0;
    }
  }
  .slick-dots {
    list-style: none;
    display: flex;
    justify-content: center;
    height: 30px;
    width: 100%;
    li {
      width: 20px;
      cursor: pointer;
      height: 20px;
      border-radius: 50%;
      border: 3px solid #d8d8d8;
      margin-right: 10px;
      button {
        visibility: hidden;
      }
      &::after {
        display: none;
      }
    }

    li.slick-active,
    li:hover {
      background-color: #9b9b9b;
      border-color: #9b9b9b;
    }
  }
}
@media only screen and (max-width: 960px) {
  .customers {
    ul.row {
      li {
        width: 100%;
        display: block;
        .customer_avatar {
          margin-bottom: 35px;
        }
      }

      .stars {
        margin: 25px 0;
      }
    }
  }
}
