$green: #17af4f;
$phone: #ffffff;
$dark: #343434;
$thirdColor: #e6f1ed;
$lp: 5%;
@import "normalize";
@import "./componetns/mixin.scss";
body {
  background-color: $phone;
  font-family: "Montserrat", sans-serif;
  // overflow-x: hidden;
}

* {
  box-sizing: border-box;
}
a {
  text-decoration: none;
  transition: all 0.5s;

  &:hover {
    text-decoration: none;
  }
}

body {
  min-width: 320px;
  -webkit-font-smoothing: antialiased;
  font-family: "Montserrat";
}
.container {
  width: 78%;
  margin: 0 auto;
  max-width: 1560px;
}
.green {
  background-color: $green;
}
.dark {
  background-color: $dark;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
img {
  max-width: 100%;
}
.slick-dots {
  list-style: none;
  display: flex;
  justify-content: center;
  height: 30px;
  width: 100%;
  li {
    width: 20px;
    cursor: pointer;
    height: 20px;
    border-radius: 50%;
    border: 3px solid #d8d8d8;
    margin-right: 10px;
    button {
      visibility: hidden;
    }
  }

  li.slick-active,
  li:hover {
    background-color: #9b9b9b;
    border-color: #9b9b9b;
  }
}
.head {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  margin-bottom: 55px;
  color: $green;
  span {
    color: #343434;
  }
}
.content_head {
  @extend .head;
  font-size: 50px;
}

.d-none {
  display: none;
}
._description {
  font-size: 14px;
  color: #4a4a4a;
  font-weight: 300;
  line-height: 22px;
  margin-top: 0;
  margin-bottom: 10px;
}

.main_b {
  background-color: $green;
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  padding: 0 22px;
  height: 60px;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    background-color: #0d4f25;
  }
  i {
    font-size: 24px;
    margin-left: 15px;
  }
}
.centered {
  text-align: center;
}
.social {
  margin-right: 30px;
  list-style: none;
  padding: 0;
  display: inline-flex;
  a {
    font-size: 20px;
    color: #fff;
    margin-right: 15px;
    &:hover {
      color: $green;
    }
  }
}
@media only screen and (max-width: 960px) {
  .container {
    width: 90%;
  }
}
@import "./componetns/jquery-ui.scss";
@import "./componetns/modal.scss";
@import "./componetns/header.scss";
@import "./componetns/nav.scss";
@import "./componetns/mobile_nav.scss";
@import "./componetns/breadcump.scss";

.social {
  margin-right: 30px;
  list-style: none;
  padding: 0;
  display: inline-flex;
  a {
    font-size: 20px;
    color: #fff;
    margin-right: 15px;
    &:hover {
      color: $green;
    }
  }
}
@import "node_modules/slick-carousel/slick/slick.scss";
@import "./componetns/slider.scss";
@import "./componetns/products.scss";
@import "./componetns/blog.scss";
@import "./componetns/loading.scss";
@import "./componetns/about.scss";
@import "./componetns/customers.scss";
@import "./componetns/subscribe.scss";
@import "./componetns/footer.scss";
@import "./componetns/content.scss";
@import "./componetns/production.scss";
@import "./componetns/partners.scss";
@import "./componetns/search.scss";
@import "./componetns/404.scss";
@import "./componetns/photo_gallery.scss";
@import "./componetns/product.scss";
@import "./componetns/contacts.scss";
@import "./componetns/animated.scss";
