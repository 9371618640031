.modal_wrap {
  display: none;
  transition: all 17s;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(#000000, 0.7);
  z-index: 1;
  left: 0;
  top: 0;
}
.modal {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: relative;
  .modal_content {
    position: relative;
    width: 80%;
    form {
      width: 715px;
      position: relative;
      margin: 0 auto;
      display: flex;
      flex: 1;
      input {
        color: #4a4a4a;
        width: 100%;
        display: block;
        background-color: #fff;
        border-radius: 5px;
        border: none;
        padding: 15px;
        height: 70px;
      }
      > button {
        width: 184px;
        height: 55px;
        border-radius: 5px;
        background-color: $green;
        color: #fff;
        border: 1px solid $green;
        transition: all 0.5s;
        cursor: pointer;
        position: absolute;
        right: 15px;
        top: 7px;
        &:hover {
          background-color: #fff;
          border-color: $green;
          color: $green;
        }
      }
    }
    h3 {
      font-weight: bold;
      font-size: 40px;
      color: $green;
      text-align: center;
      margin-bottom: 83px;
      margin-top: 115px;
      width: 100%;
    }

    ul {
      list-style: none;
      padding: 15px;
      background-color: #fff;
      color: #4a4a4a;
      border-radius: 5px;
      li {
        margin-bottom: 15px;

        span {
          color: $green;
        }
      }
    }
  }
}
.success-modal {
  .modal_content {
    width: 300px;
    .modal_close {
      top: -5px;
      right: -64px;
    }
    h3 {
      margin-bottom: 15px;
    }
  }
  .modal_content > div:not(.modal_close) {
    background: $green;
    border-radius: 5px;
    margin: 0 auto;
    color: #fff;
    padding: 15px;
    position: relative;
    text-align: center;
    p {
      font-size: 30px;
      text-align: center;
      font-weight: bold;
      line-height: 30px;
    }
    img {
      display: inline-block;
    }
  }
}
.modal_close {
  position: absolute;
  right: -120px;
  top: 5px;
  button {
    border: none;
    color: #fff;
    cursor: pointer;
    width: 56px;
    height: 56px;
    background: url("../assets/close.svg") center center no-repeat;
    &:hover,
    &:active {
      background: url("../assets/close_hover.svg") center center no-repeat;
    }
  }
}
.ui-autocomplete {
  background: transparent;
  border: none !important;
  padding-top: 10px;
  li {
    background: #fff;

    &:first-child {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    &:last-child {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}

@include respond-to(950px) {
  .seach-modal {
    background: $green;
    .modal_content {
      width: 100%;
      padding-top: 35px;
      form {
        width: 90%;
        height: 100%;
        position: static;
        input {
          height: 60px;
        }
        > button {
          background: #fff;
          position: absolute;
          color: $green;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 30px;
          left: 5%;
          width: 90%;
          top: 80%;
          &:hover {
            background: #fff;
            color: $green;
          }
        }
        .modal_close {
          right: 0px;
          top: 20px;
          button {
            background: url("../assets/mobile_close.svg") center center
              no-repeat;
          }
        }
      }
      h3 {
        color: #fff;
        text-align: left;
        width: 90%;
        margin: 0 auto;
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 300;
        margin-bottom: 30px;
      }
    }
  }
}
@include respond-to(450px) {
  .success-modal {
    .modal_content {
      width: 90%;
      h3 {
        font-size: 20px;
        font-weight: 300;
        text-align: left;
        color: #fff;
      }
      .modal_close {
        right: 10px;
        top: -60px;
        button {
          width: 48px;
          height: 48px;
        }
      }
    }
  }
}

.fancybox-slide--video .fancybox-content {
  max-width: 90%;
  max-height: calc(100% - 100px);

  margin: 0 30px;
}

.fancybox-caption {
  bottom: 15%;
  background: transparent;
  .fancybox-caption__body {
    color: #fff;
    font-weight: 300;
    font-size: 18px;
  }
}
.button-close,
.button-previous,
.button-next {
  position: absolute;
  width: 44px;
  height: 44px;
  text-align: center;
  line-height: 44px;
  color: #fff;
  text-decoration: none;
  border-radius: 50%;
  font-size: 16px;
}

.button-previous,
.button-next {
  top: 50%;
  margin-top: -22px;
  line-height: 42px;
  @include respond-to(900px) {
    top: 115%;
  }
}

.button-previous {
  left: -50px;

  @include respond-to(900px) {
    left: 40%;
  }
}

.button-next {
  right: -50px;
  @include respond-to(900px) {
    right: 40%;
  }
}

.button-close {
  top: -64px;
  right: -84px;
  @include respond-to(900px) {
    right: 10px;
  }
}
.fancybox-navigation {
  display: none;
}
