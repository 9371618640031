.blog {
  margin-bottom: 38px;
  ul {
    list-style: none;
    padding: 0;
    li {
      @include rowFlex;
      width: 48%;
      align-items: flex-start;
      flex-wrap: nowrap;
      margin-bottom: 50px;
      position: relative;

      & > div {
        width: 48%;
        position: relative;
        overflow: hidden;
      }
      .blog_avatar {
        position: relative;
        height: 300px;

        &:after {
          content: "";
          position: absolute;
          left: 0%;
          top: 123px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 27px 0 27px 27px;
          border-color: transparent transparent transparent #fff;
        }
        img {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 0;
          transition: all 0.7s;
          display: block;
          cursor: pointer;
          &:hover {
            transform: scale(1.5);
          }
        }
      }
      .blog_description {
        position: relative;
        padding-right: 15px;
        data {
          padding-top: 0;
          display: inline-flex;
          align-items: center;
          color: $green;
          font-weight: bold;
          margin-top: -10px;
          span {
            font-size: 20px;
            &:first-child {
              font-size: 60px;
              margin-right: 10px;
            }
          }
        }
        h4 {
          font-weight: 400;
          font-size: 18px;
          color: #4a4a4a;
          line-height: 22px;
          a {
            font-weight: 400;
            font-size: 18px;
            color: #4a4a4a;
            line-height: 22px;
            &:hover {
              color: $green;
            }
          }
        }
      }
      &:nth-child(4n),
      &:nth-child(4n-1) {
        .blog_description {
          order: 2;
          padding-right: 0px;
          padding-left: 15px;
        }
        .blog_avatar {
          order: 1;
          &:after {
            left: calc(100% - 27px);
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .slick-dots {
    display: flex;
    justify-content: center;
    transform: translateY(-40px);
    height: 30px;
    width: 100%;
    li {
      width: 20px;
      cursor: pointer;
      height: 20px;
      border-radius: 50%;
      border: 3px solid #d8d8d8;
      margin-right: 10px;
      button {
        visibility: hidden;
      }
    }

    li.slick-active,
    li:hover {
      background-color: #9b9b9b;
      border-color: #9b9b9b;
    }
  }
}
@media only screen and (max-width: 800px) {
  .blog {
    ul li .blog_avatar {
      height: 300px !important;
      &:after {
        display: none;
      }
      &:before {
        z-index: 99999999999999999;
        content: "";
        position: absolute;
        top: -2px;
        left: 43%;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 27px 27px 0 27px;
        border-color: #fff transparent transparent transparent;
      }
    }
    ul {
      li {
        width: 100%;
        display: block;
        .blog_description {
          margin-bottom: 15px;
        }
        > div {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
