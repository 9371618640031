.not_found {
  @include rowFlex;
  margin: 88px 0;
  div {
    text-align: center;
    width: 55%;
    align-self: flex-start;

    padding: 0 40px;
    @include respond-to(890px) {
      width: 100%;
      border-right: none;
    }
    &:first-of-type {
      border-right: 3px solid #d8d8d8;
      width: 45%;
      padding-right: 60px;
      @include respond-to(890px) {
        border-right: none;
        width: 100%;
        padding-right: 0;
      }
    }
    p {
      font-size: 45px;
      font-weight: 300;
      margin-top: 0;
      color: #9b9b9b;
    }
    a {
      display: inline-block;
      color: $green;
      border: 1px solid $green;
      text-align: center;
      padding: 15px 20px;
      font-weight: normal;
      font-size: 15px;
      margin-right: 15px;
      &:hover {
        color: #fff;
        background: $green;
      }
      &:first-of-type {
        color: #fff;
        background: $green;
        &:hover {
          color: $green;
          background: #fff;
        }
      }
    }
  }
}
