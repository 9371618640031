.product_paige {
  @include rowFlex;
  align-items: flex-start;
  ul {
    list-style: none;
    padding-left: 0;
  }

  > div:first-child {
    width: 48%;
    align-self: flex-start;
    @include respond-to(960px) {
      width: 100%;
    }
    .product_image {
      overflow: hidden;
      position: relative;
      display: block;
      border: 3px solid #e4e4e4;
      img {
        transition: all 0.6s;
        display: block;
        width: 100%;
      }
    }
  }
  > div:last-child {
    width: 48%;
    @include respond-to(960px) {
      width: 100%;
    }
    ul {
      margin-top: 0;
      &:first-child {
        margin-bottom: 30px;
        li {
          @include rowFlex;
          padding: 10px 15px;
          &:nth-child(even) {
            background: #f6f6f6;
          }
          span {
            display: inline-flex;
            color: #4a4a4a;
            font-size: 18px;
            &:first-child {
              font-weight: 300;
              width: 60%;
            }
            &:last-child {
              font-weight: bold;
              width: 40%;
            }
          }
        }
      }
      &:last-child {
        @include rowFlex;
        li {
          @include rowFlex;
          width: 50%;
          margin-bottom: 50px;
          justify-content: flex-start;
          align-items: flex-start;
          flex-wrap: nowrap;
          @include respond-to(414px) {
            flex-wrap: wrap;
          }
          img {
            margin-right: 15px;
            display: block;
            width: 74px;
            height: 74px;
            @include respond-to(414px) {
              margin: 0 auto;
              margin-bottom: 10px;
            }
          }
          div {
            padding-top: 5px;
            span {
              display: block;
              text-align: left;
              margin-bottom: 5px;
              color: #4a4a4a;
              &:first-child {
                font-weight: bold;
                font-size: 30px;
              }
              &:last-child {
                font-size: 18px;
                font-weight: 300;
              }
            }
            @include respond-to(414px) {
              width: 100%;
              text-align: center;
              span {
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
  .product_thumbnail {
    position: relative;

    ul {
      margin-left: -10px;
      margin-right: -10px;
      img {
        cursor: pointer;
        width: 100%;
      }
      .slick-slide {
        margin: 0 10px;
      }
    }
    .product_nav {
      background-color: rgba($green, 0.7);
      color: #fff;
      position: absolute;
      right: 20px;
      font-size: 30px;
      width: 68px;
      height: 68px;
      border-radius: 50%;
      transition: all 0.5s;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 20%;
      @include respond-to(400px) {
        top: 18%;
        font-size: 24px;
        width: 48px;
        height: 48px;
      }
      cursor: pointer;
      &:hover {
        background-color: rgba(#0d4f25, 0.7);
      }
      i {
        display: inline-block;
      }
    }
  }
}
