.content {
  color: #4a4a4a;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 45px;

  ul {
    list-style: none;
    padding-left: 50px;
  }

  ul li::before {
    content: "\2022";
    color: $green;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
    margin-right: 30px;
  }
  h3 {
    font-size: 18px;
    font-weight: 300;
    text-transform: uppercase;
  }
  .news_one {
    width: 75%;
    @include respond-to(700px) {
      width: 100%;
    }
    .avatar {
      img {
        width: 100%;
      }
      .title_news {
        background: $green;
        position: relative;
        color: #fff;
        width: 75%;
        padding: 15px;
        transform: translateY(-50%);
        @include respond-to(700px) {
          transform: translateY(0%);
          width: 100%;
        }
        h2 {
          font-size: 30px;
          font-weight: 700;
          line-height: 1.5;
          margin: 0;
          margin-bottom: 15px;
          @include respond-to(700px) {
            font-size: 20px;
            line-height: 1.3;
          }
        }
        data {
          background: #343434;
          display: inline-block;
          font-size: 12px;
          padding: 5px 10px;
          position: absolute;
          left: 0;
          top: -15px;
        }
      }
    }
    @include respond-to(700px) {
      margin-bottom: 10px;
    }
  }

  .share {
    margin-top: -20px;
    span {
      color: #4a4a4a;
      font-size: 14px;
      i {
        margin-right: 10px;
      }
      margin-right: 30px;
      &:nth-child(2) {
        color: $green;
        margin-right: 20px;
      }
    }
    a {
      font-size: 14px;
      color: $green;
      margin-right: 10px;
    }
  }
}
.production_slider {
  position: relative;
  img {
    height: 182px;
  }
  ul {
    list-style: none;
    padding-left: 0;
    padding-left: 0;
    margin: 40px;
  }
  .slick-center {
    transition: all 0.8s linear;
    transform: scale(1.3);
  }
  li {
    padding: 30px;
  }
}

.certificates {
  margin-bottom: 30px;
  h3 {
    font-size: 18px;
    text-transform: uppercase;
    color: #4a4a4a;
    font-weight: 300;
  }
  ul:not(.slick-dos) {
    padding-left: 0;
    li a {
      background-color: #d8d8d8;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 211px;
      width: 164px;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    p {
      width: 164px;
      margin-top: 10px;
      margin-bottom: 4px;
      color: #4a4a4a;
      font-size: 14px;
      font-weight: 400;
    }

    data {
      color: #90ab50;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .slick-dots {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    height: 30px;
    width: 100%;
    list-style: none;
    li {
      width: 20px;
      cursor: pointer;
      margin-bottom: 10px;
      height: 20px;
      border-radius: 50%;
      border: 3px solid #d8d8d8;
      margin-right: 10px;
      button {
        visibility: hidden;
      }
    }

    li.slick-active,
    li:hover {
      background-color: #9b9b9b;
      border-color: #9b9b9b;
    }
  }
}
.blog.news_slider {
  ul {
    li {
      display: flex !important;
      flex-wrap: wrap;
      height: auto;
      .blog_avatar {
        margin-right: 10px;
      }
    }
  }
  .slick-dots li {
    height: 20px;
  }
}

@media only screen and (max-width: 390px) {
  .certificates ul {
    li {
      text-align: center;
      p {
        width: 100% !important;
      }
    }
  }
}
