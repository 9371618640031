footer {
  padding: 35px 0;
  padding-bottom: 0;
  .row {
    align-items: flex-start;
  }
  .f_logo {
    width: 25%;
    img {
      width: 176px;
    }
  }
  ul {
    list-style: none;
    padding-left: 0;
    width: 20%;
    &:nth-child(4) {
      width: 35%;
      li {
        position: relative;
        padding-left: 30px;
        &::before {
          position: absolute;
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          left: 0;
          top: 0;
          color: $green;
        }

        &:first-child::before {
          content: "\f095";
        }
        &:nth-child(2)::before {
          content: "\f0e0";
        }
        &:nth-child(3)::before {
          content: "\f3c5";
        }
      }
    }
    li {
      line-height: 26px;
      a {
        color: #fff;
        font-weight: 400;
        font-size: 14px;
        text-transform: uppercase;
        span,
        &:hover {
          color: $green;
        }

        i {
          margin-right: 5px;
        }
      }
    }
    &:last-child {
      li a {
        text-transform: inherit;
      }
    }
  }

  .social {
    display: flex;
    justify-content: flex-start;
    margin-right: 0;
    width: 35%;
    padding-left: 20px;
    margin-left: auto;
    margin-top: -10px;
    a {
      color: $green;
      font-size: 20px;
      &:hover {
        color: #fff;
      }
    }
    li:last-child {
      a {
        margin-right: 0;
      }
    }
  }
}
.rights {
  background: #f6f6f6;
  color: #4a4a4a;
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 20px;
  padding-top: 5px;
  p {
    margin-bottom: 0;
    margin-top: 10px;
  }
  a {
    color: $green;
    font-weight: 700;
    @include respond-to(620px) {
      text-decoration: underline;
    }
  }
  div {
    @include respond-to(620px) {
      width: 100%;
      text-align: center;
    }
  }
}

@media only screen and (min-width: 690px) and (max-width: 1104px) {
  footer {
    ul {
      width: 33%;
    }
    ul:nth-child(2) {
      text-align: center;
    }
    .social {
      width: 50%;
    }
  }
}
@media only screen and (max-width: 690px) {
  footer {
    .f_logo {
      order: 1;
      width: 116px;
    }
    ul:nth-child(2) {
      margin-top: 0;
      order: 2;
      margin-left: auto;
      width: 33%;
      margin-bottom: 0;
    }
    ul:nth-child(3) {
      margin-left: auto;
      order: 4;
      width: 33%;
      margin-top: 0;
      padding-top: 0;
    }
    ul:nth-child(4) {
      order: 5;
      width: 100%;
    }
    .social {
      order: 3;
      width: 50%;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-left: 0;
      padding-left: 0;
      li {
        text-align: left;
        width: 34%;
      }
    }
  }
}
